import classNames from "classnames";
import { AuthAction, withAuthUser } from "next-firebase-auth";
import Head from "next/head";
import Image from "next/image";
import { inter } from "utilities/fonts";
import Link from "next/link";

function Home() {
    return (
        <>
            <Head>
                <title>
                    snack.site — custom branded website for your business ✨
                </title>
                <meta
                    property="og:title"
                    content="snack.site — custom branded website for your business ✨"
                />
                <meta name="description" content="Powered By Snackpass" />
                <link rel="icon" href="/favicon.ico" />
                <meta property="og:image" content="/images/block.png" />
            </Head>
            <div
                className={classNames(
                    "min-h-screen flex justify-center items-center flex-col",
                    inter.className,
                )}
            >
                <Image
                    src="/snackpass_logo.svg"
                    alt="Snackpass Logo"
                    width={100}
                    height={50}
                />
                <br />
                <h1 className="text-5xl font-bold mb-4">snack.site</h1>
                <br />
                <p>Custom branded websites for your business</p>
                <p className="text-center">
                    Learn more at{" "}
                    <Link href="https://snackpass.co">
                        <span className="text-blue-500 underline">
                            www.snackpass.co
                        </span>
                    </Link>
                </p>
                <br />
                <p className="text-center">
                    Already a user? &nbsp;
                    <Link href="/admin">
                        <span className="text-blue-500 underline">Sign in</span>
                    </Link>
                </p>
            </div>
        </>
    );
}

export const getStaticProps = async () => {
    return {
        props: {},
    };
};

export default withAuthUser({
    whenAuthed: AuthAction.REDIRECT_TO_APP,
    appPageURL: "/admin/",
})(Home);
